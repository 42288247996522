.table-row {
  padding: 1rem;
  display: block;
}

.header-option {
  margin-left: 1rem;
}

.repo-desc {
  margin-top: "3px";
  margin-bottom: "3px";
  max-width: 80%;
}

.stars-data {
  margin-top: -1rem;
}

/* if mobile */
@media (max-width: 768px) {
  .repo-desc {
    max-width: 100%;
  }
  .table-row {
    padding-bottom: 3rem;
  }
  .stars-data {
    margin-top: 0.75rem;
  }
}

/* if tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .repo-desc {
    max-width: 75%;
  }
  .table-row {
    padding-bottom: 2rem;
  }
}
