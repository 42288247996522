.container-lg {
  margin-right: auto;
  margin-left: auto;
  max-width: 85%;
  border-style: solid;
  border-width: 1px;
  border-radius: 6px;
}

.container-lg-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  border-style: solid;
  border-width: 1px;
  margin: -1px -1px 0;
}

.container-table {
  display: block;
  align-items: center;
  justify-content: space-between;
}

.table-header-options {
  display: inline-flex;
}

.primary-box {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

/* if mobile */
@media (max-width: 768px) {
  .container-lg {
    max-width: 95%;
  }
}

/* if tablet */
@media (min-width: 768px) and (max-width: 1024px) {
  .container-lg {
    max-width: 90%;
  }
}

.center {
  display: inline;
  align-items: center;
  text-align: center;
  height: 10vh;
}

.border-bottom {
  border-bottom: 1px solid;
}

.border-color-muted {
  border-color: rgba(110, 119, 129, 0.5) !important;
}

.main-header {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
